<template>
  <div>
    <!-- 全部信息 -->
    <div class="searchBox">
      <!-- 平台类型 -->
      <div class="searchBox-box" style="padding-top: 0rem">
        <div class="searchBox-box-L" style="position: relative; top: -0.3125rem">
          平台类型
        </div>
        <div
          class="m-l-24"
          style="
            display: flex;
            box-sizing: border-box;
            height: 3.75rem;
            padding-top: 0.9375rem;
          "
        >
          <el-checkbox class="m-l-24" v-model="checkAllOne" @change="platformCheckAll">
            全选
          </el-checkbox>
          <el-checkbox-group class="m-l-24" v-model="checkedPlatform" @change="platformChecked">
            <el-checkbox
              class="m-l-24"
              style="margin-bottom: 0.625rem"
              v-for="item in platformType"
              :label="item.value"
              :key="item.id"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <!-- 主体类型 -->
      <div class="searchBox-box">
        <div class="searchBox-box-L">主体类型</div>
        <div class="searchBox-box-R m-l-24">
          <el-checkbox class="m-l-24" v-model="checkAllTwo" @change="subjectCheckAll">
            全选
          </el-checkbox>
          <el-checkbox-group class="m-l-24" v-model="checkedSubject" @change="subjectChecked">
            <el-checkbox
              class="m-l-24"
              v-for="item in subjectType"
              :label="item.value"
              :key="item.id"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <!-- 功能 -->
      <div class="searchBox-box">
        <div
          class="searchBox-box-L"
          style="
            letter-spacing: 0.75rem;
            box-sizing: border-box;
            padding-left: 0.3125rem;
          "
        >
          功 能
        </div>
        <div class="searchBox-box-R m-l-24" style="margin-left: 0.625rem">
          <el-checkbox-group v-model="checkList" class="m-l-24">
            <el-checkbox label="1" style="margin-right: 2.8125rem">标题未匹配到关键词</el-checkbox>
            <el-checkbox label="2">标题只匹配到单个关键词</el-checkbox>
          </el-checkbox-group>
          <el-radio-group v-model="radio" class="m-l-40">
            <el-radio :label="1" style="margin-right: 4.0625rem; margin-left: 1.25rem">已读</el-radio>
            <el-radio :label="2">未读</el-radio>
          </el-radio-group>
        </div>
      </div>
      <!-- 标签 or 时间 -->
      <div class="searchBox-box text" style="border: none">
        <el-select style="width: 5.625rem" v-model="reportVlaue" placeholder="请选择">
          <el-option
            v-for="item in reportTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          class="m-l-16"
          style="width: 21.25rem"
          v-model="secrchValue"
          placeholder="内容模糊搜索"
          clearable
        ></el-input>
        <span class="timefont m-l-32">选择时间</span>
        <el-date-picker
          style="height: 2.25rem"
          class="m-l-16"
          v-model="timeArr"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
        <div class="search" @click="searchDetailsDataList">查询</div>
        <div class="reset" @click="remakeDetailsDataList">重置</div>
      </div>
    </div>
    <div style="width: 100%; height: 1rem; background: #f4f5fa"></div>
    <!-- 表格 -->
    <div shadow="never" class="m-t-16">
      <div class="tableBody">
        <el-skeleton :rows="18" animated :loading="errorloading" />
        <div class="tablebox" v-for="(item, index) in tableData" :key="index">
          <div class="tablebox-t">
            <div class="tablebox-t-l">
              <div class="biaoji" v-if="item.platformTypeName">
                {{ item.platformTypeName }}
              </div>
              <div class="desc m-l-16" @click="goDetail(item)" v-html="item.title"></div>
            </div>
            <div class="tablebox-t-r">{{ item.publishTime }}</div>
          </div>
          <div class="tablebox-c" v-html="item.content"></div>
          <div class="tablebox-b">
            <div class="tablebox-b-l">
              <div>信息来源：{{ item.author }}</div>
              <div class="m-l-40">所在地区：{{ item.areaName }}</div>
              <div class="m-l-40">
                关键词：
                <span class="key">{{ item.matchedKeyword }}</span>
              </div>
            </div>
            <div class="tablebox-b-r">
              <div v-if="!item.isCollect" @click="setCollection('1', item.id, index)">
                <img src="@/assets/img/gjh/collection.png" alt="" class="m-r-8" />
                收藏
              </div>
              <div v-if="item.isCollect" @click="setCollection('2', item.id, index)">
                <img src="@/assets/img/zhang/yishoucang.png" alt="" style="margin-right: 0.625rem" />
                <div style="color: #ff9000">已收藏</div>
              </div>
              <div class="m-l-40" @click="$main.copyFun(item.content)">
                <img src="@/assets/img/gjh/fz.png" alt="" class="m-r-8" />
                复制原文
              </div>
              <div class="m-l-40" @click="$main.copyFun(item.sourceUrl)">
                <img src="@/assets/img/gjh/copy-link.png" alt="" class="m-r-8" />
                复制链接
              </div>
              <div class="m-l-40" @click="$main.copyFun(item.joiningTogether)">
                <img src="@/assets/img/gjh/copy-content.png" alt="" class="m-r-8" />
                复制内容
              </div>
            </div>
          </div>
        </div>
        <Bitmap v-if="!errorloading && tableData.length == 0" />
        <!-- 分页 -->
        <Pagination
          class="pagingData"
          :pageNumber="page.pageNumber"
          :pageSize="page.pageSize"
          :total="total"
          @SizeChange="SizeChange"
          @CurrentChange="CurrentChange"
        ></Pagination>
      </div>
    </div>

    <!-- 删除二次确认框 -->
    <deleteDialog ref="openDialog" @deleteFn="deleteDetailsData"></deleteDialog>
    <deleteDialog ref="openDialogTwo" @deleteFn="deleteSelectDataIdList"></deleteDialog>
  </div>
</template>
<script>
import deleteDialog from "@/components/deleteDialog.vue";
import Pagination from "@/components/pagination.vue";
import Special from "@/api/special/index";
import Error from "@/api/error/index";
import Bitmap from "@/components/bitmap.vue";
export default {
  components: {
    Pagination,
    deleteDialog,
    Bitmap,
  },
  data() {
    return {
      selectDataIdList: [], //多选删除选中id
      selectNum: 0, //选中数量
      errorloading: false, //加载效果
      tableData: [], //列表数据
      checkAllOne: false, //平台类型全选
      checkedPlatform: [], //平台类型已选
      platformType: [], //平台类型
      isIndeterminateOne: false, //平台类型不确定状态
      checkAllTwo: false, //主体类型全选
      checkedSubject: [], //主体类型已选
      subjectType: [], //主体类型
      isIndeterminateTwo: false, //主体类型不确定状态
      radio: null,
      checkList: [],
      secrchValue: "", // 报告名称
      // 报告类型list
      reportTypes: [
        {
          value: "1",
          label: "标题",
        },
        {
          value: "2",
          label: "正文",
        },
        {
          value: "3",
          label: "账号名称/网站名称",
        },
      ],
      reportVlaue: "1", //报告类型
      timeArr: null, // 时间
      tablecheckAll: false,
      checked: false,
      checkedCities1: [],
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      infoList: [],
      // 详情数据id
      detailedDataId: null,
      title: "",
    };
  },
  methods: {
    // 获取所属地区
    async getAreaPathName(areaId) {
      const res = await Error.getAreaPathName(areaId);
      return res.data.data;
    },
    // 收藏/取消收藏
    async setCollection(type, data, index) {
      let url = "";
      if (type == "1") {
        url = "module-polyse/planSpecialData/collectMsg/";
      }
      if (type == "2") {
        url = "module-polyse/planSpecialData/cancelCollection/";
      }
      const res = await Special.setCollection(url, data);
      if (res.data.code == 200) {
        this.$message.success("操作成功！");
        if (type == "1") {
          this.tableData[index].isCollect = true;
        } else {
          this.tableData[index].isCollect = false;
        }
      }
    },
    // 多选删除
    async deleteSelectDataIdList(item) {
      const res = await Special.deleteSelectDataIdList(item.item.selectDataIdList);
      if (res.data.code == 200) {
        this.$message.success("删除成功！");
        setTimeout(() => {
          this.getDetailsDataList();
        }, 1000);
      }
    },
    // 删除
    async deleteDetailsData(item) {
      const res = await Special.deleteDetailsData(item.item.id);
      if (res.data.code == 200) {
        this.$message.success("删除成功！");
        this.getDetailsDataList();
      }
    },
    // 打开删除确认框
    openError(type, item) {
      if (type == "1") {
        if (this.selectDataIdList.length == 0) {
          this.$message.warning("请选择要删除的数据！");
          return false;
        }
        item.message = "选中数据";
        this.$refs.openDialogTwo.openDialog(item);
      }
      if (type == "2") {
        item.message = "选中数据";
        this.$refs.openDialog.openDialog(item);
      }
    },
    // 重置
    remakeDetailsDataList() {
      this.checkedPlatform = [];
      this.checkAllOne = false;
      this.checkedSubject = [];
      this.checkAllTwo = false;
      this.checkList = [];
      this.radio = null;
      this.reportVlaue = "1";
      this.secrchValue = "";
      this.timeArr = null;
      this.page = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.total = 0;
      this.getDetailsDataList();
    },
    // 查询
    searchDetailsDataList() {
      this.page = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.total = 0;
      this.getDetailsDataList();
    },
    // 获取数据列表
    async getDetailsDataList() {
      this.tablecheckAll = false;
      this.selectNum = 0;
      this.selectDataIdList = [];
      this.checkedCities1 = [];
      this.tableData = [];
      this.errorloading = true;
      let data = {
        page: this.page.pageNumber,
        limit: this.page.pageSize,
        isCollect: 1,
      };
      if (this.checkedPlatform.length > 0) {
        data.platformTypes = this.checkedPlatform.join(",");
      }
      if (this.checkedSubject.length > 0) {
        data.bodyTypes = this.checkedSubject.join(",");
      }
      if (this.radio) {
        if (this.radio == 1) {
          data.isRead = 1;
        }
        if (this.radio == 2) {
          data.isRead = 0;
        }
      }
      if (this.checkList && this.checkList.length > 0) {
        if (this.checkList.indexOf("1") != -1) {
          data.titleNotMatchKeyword = true;
        }
        if (this.checkList.indexOf("2") != -1) {
          data.titleMatchKeyword = true;
        }
      }
      if (this.secrchValue && this.secrchValue != "") {
        this.reportVlaue == "1"
          ? (data.title = this.secrchValue)
          : this.reportVlaue == "2"
          ? (data.content = this.secrchValue)
          : this.reportVlaue == "3"
          ? (data.author = this.secrchValue)
          : "";
      }
      if (this.timeArr && this.timeArr.length > 0) {
        data.publishTimeStart = this.timeArr[0];
        data.publishTimeEnd = this.timeArr[1];
      }
      const res = await Special.getDetailsDataList(data);
      if (res.data.code == 200) {
        this.total = res.data.data.totalCount / 1;
        res.data.data.list.forEach((x) => {
          this.platformType.forEach((y) => {
            if (x.platformType == y.value) {
              x.platformTypeName = y.label;
            }
          });
        });
        // this.tableData = res.data.data.list;
        this.tableData = this.$main.listImgNo(res.data.data.list);
        this.errorloading = false;
      } else {
        this.tableData = [];
        this.errorloading = false;
      }
    },
    // 获取平台类型
    async getPlatformList() {
      const res = await Error.getPlatformList();
      if (res.data.code == 200) {
        this.platformType = res.data.data;
      }
    },
    // 获取主体类型
    async getSubjectList() {
      const res = await Error.getSubjectList();
      if (res.data.code == 200) {
        this.subjectType = res.data.data;
      }
    },
    //全选平台类型
    platformCheckAll(val) {
      let checked = this.platformType.map((item) => {
        return item.value;
      });
      this.checkedPlatform = val ? checked : [];
      this.isIndeterminateOne = false;
    },
    //多选平台类型
    platformChecked(value) {
      let checkedCount = value.length;
      this.checkAllOne = checkedCount === this.platformType.length;
      this.isIndeterminateOne = checkedCount > 0 && checkedCount < this.platformType.length;
    },
    //主体类型全选
    subjectCheckAll(val) {
      let checked = this.subjectType.map((item) => {
        return item.value;
      });
      this.checkedSubject = val ? checked : [];
      this.isIndeterminateTwo = false;
    },
    //主体类型多选
    subjectChecked(value) {
      let checkedCount = value.length;
      this.checkAllTwo = checkedCount === this.subjectType.length;
      this.isIndeterminateTwo = checkedCount > 0 && checkedCount < this.subjectType.length;
    },
    //全选列表数据
    handleCheckAllChange(val) {
      this.checkedCities1 = val ? this.tableData : [];
      let arr = [];
      if (val) {
        this.tableData.map((item) => {
          arr.push(item.id);
          return item.id;
        });
        this.selectNum = this.tableData.length;
      } else {
        this.selectNum = 0;
        arr = [];
      }
      this.selectDataIdList = arr;
    },
    // 多选列表数据
    handleCheckedCitiesChange1(value, item) {
      this.current == true ? this.infoList.push(item) : this.infoList.pop(item);
      let checkedCount = value.length;
      this.tablecheckAll = checkedCount === this.tableData.length;
      let arr = [];
      for (let i in value) {
        arr.push(value[i].id);
      }
      this.selectNum = this.checkedCities1.length;
      this.selectDataIdList = arr;
    },
    CurrentChange(val) {
      this.page.pageNumber = val;
    },
    // 去详情
    goDetail(item) {
      let routeData = this.$router.resolve({
        path: "/settings/specialDetail",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.id,
              title: this.title,
              specialActionsId: this.detailedDataId,
              platformType: this.platformType,
              subjectType: this.subjectType,
            })
          ),
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 分页
    SizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNumber = 1;
      this.getDetailsDataList();
    },
    CurrentChange(val) {
      this.page.pageNumber = val;
      this.getDetailsDataList();
    },
  },
  mounted() {},
  created() {
    this.getPlatformList();
    this.getSubjectList();
    this.getDetailsDataList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-range-separator {
  width: 24px;
}
.searchBox {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 0px 35px;
  cursor: pointer;
  .searchBox-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e4e6ec;
    .search {
      display: flex;
      // align-items: center;
      // justify-content: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      width: auto;
      // height: 32px;
      background: #f58030;
      border-radius: 4px;
      padding: 6px 16px;
      cursor: pointer;
      position: absolute;
      right: 120px;
    }
    .reset {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e1e1e1;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
      position: absolute;
      right: 45px;
    }
    .timefont {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .searchBox-box-L {
      text-align: right;
      min-width: 60px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #999999;
    }
    .searchBox-box-R {
      display: flex;
      align-items: center;
    }
  }
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333333;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e6ec;
  position: sticky;
  background-color: #fff;
  top: 80px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  z-index: 100;
  .table-header-L {
    display: flex;
    justify-content: flex-start;

    span {
      color: #f58030;
    }
  }
  .table-header-R {
    display: flex;
    flex-direction: row;
    div {
      padding: 8px 9px;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    .d {
      background: #fff1e8;
      color: #ed731f;
    }
    .s {
      color: #ea3342;
      background: #fff2f3;
    }
  }
}
.tableBody {
  padding: 0 30px 30px 30px;
  .tablebox {
    border-bottom: 1px solid #e4e6ec;
    background: #ffffff;
    padding-bottom: 20px;
    .tablebox-t {
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      .tablebox-t-l {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        width: 90%;
        .biaoji {
          font-size: 14px;
          color: #ffffff;
          font-weight: 500;
          padding: 4px 8px;
          background: #f58030;
          min-width: 40px;
          flex-shrink: 0;
        }
        .desc {
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 70%;
        }
      }
      .tablebox-t-r {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .tablebox-c {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      max-height: 60px;
      overflow: hidden;
    }
    .tablebox-b {
      padding: 16px 0 0 0;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      .tablebox-b-l {
        color: #666666;
        display: flex;
        justify-content: flex-start;
        .key {
          color: #ea3342;
        }
      }
      .tablebox-b-r {
        display: flex;
        justify-content: flex-start;
        div {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
.pagingData {
  background: #ffffff;
  margin: 0px;
  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 10px;
}
</style>
