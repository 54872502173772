<template>
    <div>
        <el-card shadow="never">
            <div class="query-card">
                <div class="query-box">
                    <el-select v-model="wordType" placeholder="请选择">
                        <el-option label="正确词" :value="0"></el-option>
                        <el-option label="错误词" :value="1"></el-option>
                    </el-select>
                    <el-input placeholder="请输入正确词或错误词" v-model="wordInput" class="input-with-select">
                    </el-input>
                    <el-button type="primary" @click="searchWord">查询</el-button>
                    <el-button @click="resetWord">重置</el-button>
                </div>
                <div>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addWordGroup">新增词组</el-button>
                    <el-button type="warning" icon="el-icon-time" @click="wordsOperationHistory">词库操作历史</el-button>
                </div>
            </div>
        </el-card>
        <el-card shadow="never" v-loading="listLoading">
            <div class="empty-container" v-if="words?.length === 0 || !words">
                <img src="@/assets/img/gjh/weitu.png" />
                <p>暂无数据</p>
            </div>
            <div class="words-states" v-if="words?.length > 0">
                <div v-for="(item, index) in words" :key="index" class="mb-10">
                    <el-divider v-if="index > 0"></el-divider>
                    <div class="header">
                        <div class="left-section">
                            <div class="status-icon success">
                                <i class="el-icon-check"></i>
                            </div>
                            <span class="label">正确词</span>
                            <span class="description">{{ item.correctWord.word }}</span>
                        </div>
                        <el-link type="danger" :underline="false" @click="deleteWordGroup(item)">删除</el-link>
                    </div>
                    <el-card class="scroll-section" shadow="never">
                        <div class="flex-space-between">
                            <div class="error-label">
                                <div class="status-icon error">
                                    <i class="el-icon-close"></i>
                                </div>
                                <span class="label">错误词</span>
                            </div>
                            <div class="flex-space-between h-40">
                                <el-link type="primary" :underline="false" @click="addWord(item)" v-if="!item.isAdding"
                                    :disabled="item.isEditing">新增
                                </el-link>
                                <div v-if="item.isAdding" class="flex-space-around">
                                    <el-input v-model="item.newWord" placeholder="请输入错误词"
                                        @keyup.enter.native="confirmWord(item)" @input="validateInput(item)"></el-input>
                                    <el-link :underline="false" type="primary" @click="confirmWord(item)"
                                        class="ml-10 w-50">添加</el-link>

                                    <el-link :underline="false" @click="cancelWord(item)"
                                        class="ml-10 w-50">取消</el-link>
                                </div>
                                <el-link :type="item.isEditing ? '' : 'primary'" :underline="false" class="ml-10"
                                    :disabled="item.isAdding" @click="toggleEdit(item)">
                                    {{ item.isEditing ? "取消" : "编辑" }}
                                </el-link>
                            </div>
                        </div>
                        <el-divider></el-divider>
                        <div class="flex-space-baseline">
                            <div class="scroll-content" :class="item.isOverflow && !item.showAll
                                ? 'overflow-auto'
                                : 'overflow-hidden'
                                " :ref="'scrollContent' + item.correctWord.id">
                                <div v-for="(n, i) in getWordsToShow(item)" :key="i" class="words-item"
                                    :class="{ active: n.hitWord === 1 && wordType === 1 }">
                                    {{ n.word }}
                                    <el-link type="danger" class="delete-link" :underline="false" v-if="item.isEditing"
                                        @click="deleteWrongWord(item, n, i)">
                                        <img src="@/assets/img/myWord/delete-icon.png" alt="" />
                                    </el-link>
                                </div>
                            </div>
                            <i class="el-icon-arrow-up arrow-icon" v-if="item.isOverflow && !item.showAll"
                                @click="toggleShowAll(item)"></i>
                            <i class="el-icon-arrow-down arrow-icon" v-if="!item.isOverflow && item.showAll"
                                @click="toggleShowAll(item)"></i>
                        </div>
                    </el-card>
                </div>
            </div>
            <Pagination :pageNumber="page.pageNumber" :pageSize="page.pageSize"
                :total="(words?.length == 0 || !words) ? 0 : total" @SizeChange="SizeChange"
                @CurrentChange="CurrentChange">
            </Pagination>
        </el-card>
        <el-dialog title="添加错误词表述词组" :visible.sync="dialogFormVisible" v-loading="dialogLoading"
            @close="cancelDialogWords">
            <div class="dialog-content">
                <div class="text-title">添加词库</div>
                <el-alert :closable="false"
                    title="提示:可以多个错误词对应一个正确词，也可以一个错误词对应一个正确词，当有多个错误词时，输入错误词后按下回车键，当前输入的错误词将自动转换为一个独立的元素，可继续在输入框中输入下一个错误词，并重复上述操作。添加机构词后10分钟内生效。"
                    type="warning" show-icon class="alert-box">
                </el-alert>
                <div class="text-title" style="margin-top: 2rem">
                    <div>关键词组<span class="text-red"> 仅支持中文、阿拉伯数字、字母，符号支持“” ‘’、，（ ）《》以及空格</span></div>
                    <el-button style="margin-left: 1.25rem" type="primary" size="small" @click="addFn">添 加</el-button>
                </div>
                <el-form ref="form" label-width="100px" class="form-style">
                    <el-form-item label="错误词" required>
                        <el-input v-model="cwc" @keyup.enter.native="addKeyWordTag" placeholder="请输入错误词,按回车键添加"
                            @input="validateInputCwc"></el-input>
                        <div class="tagsRegion">
                            <div v-for="(item, index) in keyWordTags" :key="index" class="tagsRegion-card">
                                {{ item }}
                                <img src="@/assets/img/wang/fill-del.png" @click="deldetkeyWord(index)" />
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="正确词" class="mb-10" required>
                        <el-input v-model="zqc" placeholder="请输入正确词" @input="validateInputZqc"></el-input>
                    </el-form-item>
                </el-form>
                <el-divider content-position="center" v-if="keywordlist.length > 0">已添加的关键词</el-divider>
                <el-row v-for="(item, inx) in keywordlist" :key="inx" :gap="10">
                    <el-col :span="11" class="flex-center">
                        <el-input disabled class="input-disabled" placeholder="错误词"></el-input>
                        <el-input disabled class="input-enable" v-model="item.newWrongWords" :title="item.newWrongWords"
                            placeholder="错误词" clearable></el-input>
                    </el-col>
                    <el-col :span="11" class="flex-center">
                        <el-input disabled class="input-disabled" placeholder="正确词"></el-input>
                        <el-input disabled class="input-enable" v-model="item.correctWord" placeholder="正确词"
                            :title="item.correctWord" clearable></el-input></el-col>
                    <el-col :span="2" class="flex-center">
                        <el-link type="danger" :underline="false" @click="deleteFn(inx)" class="delete-link-item">删
                            除</el-link></el-col>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialogWords">取 消</el-button>
                <el-button type="primary" @click="addWords">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="确认删除" :visible.sync="dialogVisible" width="30%">
            <span>您确定要删除这条信息吗？此操作无法撤回。</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteCorrectWord">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import {
    createOrgMisreWords,
    delOrgCorrectWord,
    delOrgWrongWord,
    queryOrgMisreWords,
} from "@/api/myWords";

export default {
    components: { Pagination },
    data() {
        return {
            observer: null, // 观察者
            dialogLoading: false, // 添加错误词弹窗加载状态
            listLoading: false, // 我的词库列表加载状态
            wordType: "", // 词库类型
            wordInput: "", // 词库输入框
            page: {
                pageNumber: 1, // 当前页码
                pageSize: 10, // 每页条数
            },
            total: 0, // 总条数
            dialogFormVisible: false, // 添加错误词弹窗
            dialogVisible: false, // 删除弹窗
            keyWordTags: [], // 错误词标签
            cwc: "", // 错误词
            zqc: "", // 正确词
            keywordlist: [], // 关键词组
            formLabelWidth: "120px", // 表单label宽度
            words: [], // 我的词库列表
            planId: "", // 计划id
            correctWordId: "", // 正确词id
            // pattern: /^[\u4e00-\u9fa5\u3400-\u4db5a-zA-Z0-9。，！？：“”‘’（）【】《》……—·.,!?:"'()\[\]<>-—_&@#　 ]+$/,
            pattern: /^[\u4e00-\u9fa5\u3400-\u4db5a-zA-Z0-9，“” ‘’ 、（ ）《》　 ]+$/,
            // pattern: /^[\u4e00-\u9fa5\u3000-\u303f\uff00-\uffefA-Za-z0-9。，！？：“”‘’（）【】《》……—.,!?:"'()[\]<>-—_&@#\s]+$/,
            // regex: /^(?=(?:.*[。，！ ？：“”‘’（）【】《》……—.,!?:"'()[\]<>-—_&@#])?)(?=(?:.*[\u4E00-\u9FFF\u3400-\u4DBF\u20000-\u2A6DFA-z0-9]){2}|(?:.*[A-Za-z]){2}|(?:.*[0-9]){2})([\u4E00-\u9FFF\u3400-\u4DBF\u20000-\u2A6DFA-Za-z0-9。，！？：“”‘’（）【】《》……—.,!?:"'()[\]<> -—_&@#]+)$/,
            regex: /^(?=(?:.*[，“” ‘’、 （ ）《》　 ])?)(?=(?:.*[\u4E00-\u9FFF\u3400-\u4DBF\u20000-\u2A6DFA-z0-9]){2}|(?:.*[A-Za-z]){2}|(?:.*[0-9]){2})([\u4E00-\u9FFF\u3400-\u4DBF\u20000-\u2A6DFA-Za-z0-9，“” ‘’ 、（ ）《》　 ]+)$/,

        };
    },
    async mounted() {
        await this.getWordsList();
        this.observeItem();
        window.addEventListener("resize", () => { this.observeItem })
    },

    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
        window.removeEventListener("resize", () => { this.observeItem })
    },
    watch: {
        cwc(newVal) {
            this.validateInputCwc(newVal);
        },
        zqc(newVal) {
            this.validateInputZqc(newVal);
        },
    },

    methods: {
        // 取消关闭弹窗
        cancelDialogWords() {
            this.dialogFormVisible = false;
            this.resetForm();

        },
        validateInput(item) {
            // 使用正则表达式进行校验
            if (!this.pattern.test(item.newWord)) {
                // 如果不匹配，则移除不符合的部分
                this.$nextTick(() => {
                    item.newWord = item.newWord.replace(/[^[\u4e00-\u9fa5\u3400-\u4db5a-zA-Z0-9，“” ‘’ 、（ ）《》　 ]+$/g, '');
                });
            }
        },

        validateInputCwc(newVal) {
            if (!this.pattern.test(newVal)) {
                // this.cwc = newVal.replace(/[^[\u4e00-\u9fa5\u3000-\u303f\uff00-\uffefA-Za-z0-9。，！？：“”‘’（）【】《》……—.,!?:"'()[\]<>-—_&@#\s]+$/g, ''); // 保留匹配的部分
                this.$nextTick(() => {
                    this.cwc = newVal.replace(/[^[\u4e00-\u9fa5\u3400-\u4db5a-zA-Z0-9，“” ‘’ 、（ ）《》　 ]+$/g, ''); // 保留匹配的部分
                })
                // this.cwc = newVal.replace(/[^[\u4e00-\u9fa5\u3400-\u4db5a-zA-Z0-9，“” ‘’ 、（ ）《》　 ]+$/g, ''); // 保留匹配的部分
            }
        },

        validateInputZqc(newVal) {
            if (!this.pattern.test(newVal)) {
                // this.zqc = newVal.replace(/[^[\u4e00-\u9fa5\u3000-\u303f\uff00-\uffefA-Za-z0-9。，！？：“”‘’（）【】《》……—.,!?:"'()[\]<>-—_&@#\s]+$/g, ''); // 保留匹配的部分
                this.$nextTick(() => {
                    this.zqc = newVal.replace(/[^[\u4e00-\u9fa5\u3400-\u4db5a-zA-Z0-9，“” ‘’ 、（ ）《》　 ]+$/g, ''); // 保留匹配的部分
                })
                // this.zqc = newVal.replace(/[^[\u4e00-\u9fa5\u3400-\u4db5a-zA-Z0-9，“” ‘’ 、（ ）《》　 ]+$/g, ''); // 保留匹配的部分
            }
        },


        // 监听每个item
        observeItem() {
            this.words?.forEach((item) => {
                this.observeOverflow(item);
            });
        },
        // 获取错误词组列表
        async getWordsList() {
            this.listLoading = true;
            try {
                const data = {
                    limit: this.page.pageSize,
                    page: this.page.pageNumber,
                    queryType: this.wordType,
                    word: this.wordInput,
                };
                const res = await queryOrgMisreWords(data);
                if (res.data.code === 200) {
                    const reslut = res.data.data;
                    this.words = reslut.pageUtils?.list.map((item) => {
                        return {
                            ...item,
                            showAll: false,
                            isEditing: false,
                            isAdding: false,
                            isOverflow: false,
                        };
                    });
                    this.total = Number(reslut.pageUtils?.totalCount);
                    this.planId = reslut.planId;
                }
            } catch (error) {
                this.$message.error(error.message);
            } finally {
                this.listLoading = false;
            }
        },
        // 提交错误词组
        async addWords() {
            if (this.keywordlist.length === 0) {
                this.$message.error("请输入正确词或错误词");
                return;
            }
            this.dialogLoading = true;
            try {
                const data = {
                    planId: this.planId,
                    words: this.keywordlist,
                };
                const res = await createOrgMisreWords(data);
                if (res.data.code === 200) {
                    this.dialogFormVisible = false;
                    this.resetForm();
                    this.$message.success(res.data.data);
                    await this.getWordsList();
                    this.observeItem();
                } else {
                    this.$message.error("添加失败");
                }
            } catch (error) {
            } finally {
                this.dialogLoading = false;
            }
        },
        // 重置错误词组表单
        resetForm() {
            this.cwc = "";
            this.zqc = "";
            this.keyWordTags = [];
            this.keywordlist = [];
        },
        // 打开词库操作历史页面
        wordsOperationHistory() {
            let routeUrl = this.$router.resolve({
                path: "/settings/wordsOperationHistory",
            });
            window.open(routeUrl.href);
        },
        // 监听错误词DOM变化
        observeOverflow(item) {
            // 获取错误词组内容节点
            const wordList = this.$refs["scrollContent" + item.correctWord.id];
            if (!wordList) return; // 如果 ref 不存在，直接返回
            // 首次检查溢出
            this.checkOverflow(item);
            // 监听内容变化
            const observer = new MutationObserver(() => {
                this.checkOverflow(item);
            });
            // 观察目标节点
            observer.observe(wordList[0], {
                childList: true, // 监听子元素的变化
                subtree: true, // 监听所有后代节点的变化
            });
            // 保存 observer
            this.observer = observer;
        },
        // 检查是否溢出
        checkOverflow(item) {
            const wordList = this.$refs["scrollContent" + item.correctWord.id];
            if (!wordList) return; // 如果 ref 不存在，直接返回
            // 判断内容是否超出容器
            const isOverflow = wordList[0]?.scrollHeight > wordList[0]?.clientHeight;
            item.isOverflow = isOverflow;
        },
        // 弹窗中添加单个错误词
        addKeyWordTag() {
            if (this.cwc.trim() === "")
                return this.$message.warning("错误词不能为空！");
            if (this.keyWordTags.includes(this.cwc))
                return this.$message.warning("错误词已存在！");
            if (this.cwc.length > 100) {
                return this.$message.warning("您输入的内容已超限，请将内容控制在100个长度以内后重新输入。");
            }
            if (this.cwc.length < 2) {
                return this.$message.warning("您输入的内容长度不足，请重新输入至少2个长度的内容。");
            }
            if (this.cwc.trim().length < 2) {
                return this.$message.warning("词前词后不允许出现空格");
            }
            if (this.cwc.trim() === this.zqc.trim()) {
                return this.$message.warning("错误词不能与正确词相同");
            }
            // 正则输入的字符串中包含至少两个汉字、字母或数字的组合，并且不能出现单独的一个汉字、字母或数字
            const matches = this.regex.test(this.cwc);
            if (!matches) {
                return this.$message.warning("添加的词不符合系统规则");
            }
            this.keyWordTags = [...this.keyWordTags, this.cwc.trim()];
            this.cwc = "";
        },
        // 弹窗中移除单个错误词  不调接口
        deldetkeyWord(index) {
            this.keyWordTags.splice(index, 1);
        },
        // 弹窗中移除单行关键词
        deleteFn(inx) {
            this.keywordlist.splice(inx, 1);
        },
        // 弹窗中添加单行关键词
        addFn() {
            if (this.keyWordTags.length === 0) { return this.$message.warning("错误词是必填项！"); }
            if (this.zqc.trim() === "") { return this.$message.warning("正确词不能为空！"); }
            if (this.zqc.length > 100) {
                return this.$message.warning("您输入的内容已超限，请将内容控制在100个长度以内后重新输入。");
            }
            if (this.zqc.length < 2) {
                return this.$message.warning("您输入的内容长度不足，请重新输入至少2个长度的内容。");
            }
            if (this.zqc.trim().length < 2) {
                return this.$message.warning("词前词后不允许出现空格");
            }
            if (this.keyWordTags?.includes(this.zqc.trim())) {
                return this.$message.warning("错误词不能与正确词相同");
            }
            // 正则输入的字符串中包含至少两个汉字、字母或数字的组合，并且不能出现单独的一个汉字、字母或数字
            const matches = this.regex.test(this.zqc);
            if (!matches) {
                return this.$message.warning("添加的词不符合系统规则");
            }
            let cwc = this.keyWordTags.join(", ");
            let zqc = this.zqc.trim();
            this.keywordlist = [
                ...this.keywordlist,
                { correctWord: zqc, wrongWords: this.keyWordTags, newWrongWords: cwc },
            ];
            this.cwc = "";
            this.zqc = "";
            this.keyWordTags = [];
        },
        // 搜索词
        async searchWord() {
            this.page.pageNumber = 1;
            this.page.pageSize = 10;
            await this.getWordsList();
            this.observeItem();
        },
        // 重置词
        async resetWord() {
            this.wordInput = "";
            this.wordType = "";
            this.page.pageNumber = 1;
            this.page.pageSize = 10;
            await this.getWordsList();
            this.observeItem();
        },
        // 新增词组
        addWordGroup() {
            this.dialogFormVisible = true;
        },
        // 新增词
        addWord(item) {
            item.isAdding = true;
        },
        // 取消新增词
        cancelWord(item) {
            item.newWord = "";
            item.isAdding = false;
        },
        // 确认新增词
        async confirmWord(item) {
            // 确保有输入的新错误词
            if (!item.newWord || item.newWord.trim() === "") {
                this.$message.warning("请输入有效的错误词");
                return;
            }

            // 确保错误词不重复
            if (item.wrongWords.some((subItem) => subItem.word === item.newWord)) {
                this.$message.warning("错误词重复,请重新输入");
                return;
            }
            if (item.newWord.length > 100) {
                return this.$message.warning("您输入的内容已超限，请将内容控制在100个长度以内后重新输入。");
            }
            if (item.newWord.length < 2) {
                return this.$message.warning("您输入的内容长度不足，请重新输入至少2个长度的内容。");
            }
            if (item.newWord.trim().length < 2) {
                return this.$message.warning("词前词后不允许出现空格");
            }
            if (item.newWord.trim() === item.correctWord.word.trim()) {
                return this.$message.warning("错误词不能与正确词相同");
            }
            // 正则输入的字符串中包含至少两个汉字、字母或数字的组合，并且不能出现单独的一个汉字、字母或数字
            const matches = this.regex.test(item.newWord);
            if (!matches) {
                return this.$message.warning("添加的词不符合系统规则");
            }


            const data = {
                planId: this.planId,
                words: [
                    {
                        correctWord: item.correctWord.word,
                        wrongWords: [item.newWord.trim()],
                    },
                ],
            };
            const res = await createOrgMisreWords(data);
            if (res.data.code === 200) {
                // item.wrongWords.push({ word: item.newWord });
                await this.getWordsList();
                this.observeItem();
                item.newWord = "";
                item.isAdding = false; // 关闭新增词的状态
                this.$message.success(res.data.data);
            }
        },
        // 显示全部
        toggleShowAll(item) {
            if (item.isEditing) return;
            item.showAll = !item.showAll;
            item.isOverflow = !item.isOverflow;
            item.isEditing = false; // 关闭编辑状态
        },

        // 分页
        async SizeChange(newPageSize) {
            try {
                this.page.pageSize = newPageSize;
                await this.getWordsList();
                this.observeItem();
            } catch (error) {
                this.$message.error(error.message);
            }
        },
        // 页码
        async CurrentChange(newPageNumber) {
            try {
                this.page.pageNumber = newPageNumber;
                await this.getWordsList();
                this.observeItem();
            } catch (error) {
                this.$message.error(error.message);
            }
        },

        // 是否充满一行
        isRowFull(item) {
            const wordList = this.$refs["scrollContent" + item.correctWord.id];
            if (!wordList) return false; // 如果 ref 不存在，直接返回 false
            return wordList[0].scrollHeight > wordList[0].clientHeight;
        },
        // 编辑
        toggleEdit(item) {
            if (this.isRowFull(item)) {
                setTimeout(() => {
                    item.showAll = false;
                    item.isOverflow = true;
                });
            }
            item.isEditing = !item.isEditing; // 切换编辑状态
            this.checkOverflow(item); // 检查溢出状态
        },
        // 打开删除弹窗
        deleteWordGroup(item) {
            this.dialogVisible = true;
            this.correctWordId = item.correctWord.id;
        },

        // 删除正确词
        async deleteCorrectWord() {
            const res = await delOrgCorrectWord(this.correctWordId);
            if (res.data.code === 200) {
                this.$message.success("删除成功");
                this.dialogVisible = false;

                // 分页删除后跳转上一页解决方式1
                // 假设this.currentPage是当前页码，this.pageSize是每页显示的数据条数，this.total是数据总条数
                const currentPageDataCount = this.total - (this.page.pageNumber - 1) * this.page.pageSize;
                if (currentPageDataCount <= 1 && this.page.pageNumber > 1) {
                    // 如果当前页是最后一页且数据条数小于等于1，则跳转到上一页
                    this.page.pageNumber -= 1;
                }

                // // 分页删除后跳转上一页解决方式2
                // const totalPage = Math.ceil(
                //     (this.total - 1) / this.page.pageSize
                // );
                // this.page.pageNumber = this.page.pageNumber > totalPage ? totalPage : this.page.pageNumber;
                // this.page.pageNumber = this.page.pageNumber < 1 ? 1 : this.page.pageNumber;
                await this.getWordsList();
                this.observeItem();
            }
        },

        // 删除最后一个错误词
        async deleteWord(subItem) {
            try {
                const response = await delOrgWrongWord(subItem.id);
                if (response.data.code === 200) {
                    this.$message.success("删除成功");
                    const currentPageDataCount = this.total - (this.page.pageNumber - 1) * this.page.pageSize;
                    if (currentPageDataCount <= 1 && this.page.pageNumber > 1) {
                        // 如果当前页是最后一页且数据条数小于等于1，则跳转到上一页
                        this.page.pageNumber -= 1;
                    }
                    await this.getWordsList(); // 等待获取词汇列表完成
                    this.observeItem();
                }
            } catch (error) {
                this.$message.info("删除已取消");
            }
        },
        // 删除错误词
        deleteWrongWord(item, subItem, index) {
            try {
                // 提示用户确认删除
                if (item.wrongWords.length === 1) {
                    this.$confirm("您确定要删除该错误词吗？此操作无法撤回。", "警告", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.deleteWord(subItem);
                        })
                        .catch(() => {
                            this.$message.info("删除已取消");
                        });
                } else {
                    delOrgWrongWord(subItem.id).then((response) => {
                        if (response.data.code === 200) {
                            item.wrongWords.splice(index, 1);
                            this.checkOverflow(item); // 检查溢出状态
                            this.$message.success("删除成功");
                        }
                    });
                }
            } catch (error) {
            } finally {
            }
        },

        // 获取展示的词
        getWordsToShow(item) {
            return item.wrongWords;
        },
    },
};
</script>

<style lang="scss" scoped>
.empty-container {
    text-align: center;

    img {
        margin-top: 20px;
    }

    p {
        color: #999999;
        font-size: 14px;
    }
}

.query-card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .query-box {
        display: flex;
    }
}

.ml-10 {
    margin-left: 10px;
}

.input-with-select {
    width: 200px;
    margin-right: 10px;
    margin-left: 10px;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-space-baseline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.flex-space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-center {
    display: flex;
    align-items: center;
}

.d-flex {
    display: flex;
}

.w-50 {
    width: 50px;
}

.mb-10 {
    margin-bottom: 10px;
}

.words-states {
    padding: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.left-section {
    display: flex;
    align-items: center;
    gap: 8px;
}

.status-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.status-icon.success {
    background-color: #67c23a;
    color: white;
}

.status-icon.error {
    background-color: #f56c6c;
    color: white;
}

.status-icon i {
    font-size: 12px;
}

.label {
    font-size: 14px;
    color: #333;
}

.description {
    font-size: 14px;
    color: #666;
    margin-left: 8px;
}

.scroll-section {
    gap: 12px;
    margin-left: 28px;
    background-color: #f5f6fa;
    position: relative;
}

.error-label {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

.scroll-content {
    display: flex;
    gap: 12px;
    position: relative;
    flex-wrap: wrap;
}

.overflow-hidden {
    overflow: hidden;
    min-height: 35px;
    max-height: 48px;

}

.overflow-auto {
    height: auto;
}

.words-item {
    background: #ffffff;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    color: #606266;
    white-space: nowrap;
    min-width: 100px;
    text-align: center;
    position: relative;
}

.arrow-icon {
    color: #909399;
    font-size: 20px;
    flex-shrink: 0;
    cursor: pointer;
}

// 删除图标的样式
.words-item .delete-link {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;

    img {
        width: 16px;
        height: 16px;
    }
}

.delete-link-item {
    height: 40px;
    line-height: 40px;
}

.dialog-content {
    padding: 0 20px;
    max-height: 550px;
    overflow-y: auto;

    .text-title {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .inputBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .correct-word {
        text-align: right;
    }

    .input-disabled {
        width: 100px;
        margin-right: 10px;
    }

    .input-enable {
        margin-right: 10px;

        ::v-deep .el-input__inner {
            color: #333333;

        }
    }
}

.tagsRegion {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .tagsRegion-card {
        padding: 6px 8px;
        background: #ffffff;
        border-radius: 4px;
        font-size: 14px;
        color: #666666;
        display: flex;
        align-items: center;
        margin-right: 16px;
        margin-top: 16px;
        line-height: 1;

        img {
            width: 16px;
            height: 16px;
            margin-left: 16px;
            cursor: pointer;
        }
    }
}

.alert-box {
    display: flex;
    align-items: baseline;
    border: 1px solid #f1924e;

    ::v-deep .el-alert__title {
        color: #323233;
    }
}

.form-style {
    background: #f5f6fa;
    border-radius: 4px;
    padding: 10px;
}

// 错误词高亮
.active {
    background-color: #f1924e;
    color: #ffffff;
}

.h-40 {
    height: 40px;
}

.text-red {
    color: red;
}
</style>