<template>
  <div class="pageBox">
    <!-- 筛选 -->
    <div class="platform-head">
      <div class="platform-type">
        <div class="platform-type-left">平台类型</div>
        <div class="platform-type-right">
          <el-checkbox class="check-all" v-model="checkAllOne" @change="platformCheckAll">
            全选
          </el-checkbox>
          <el-checkbox-group
            class="checkbox-group"
            v-model="checkedPlatform"
            @change="platformChecked"
          >
            <el-checkbox
              class="check-more"
              v-for="item in platformType"
              :label="item.value"
              :key="item.id"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="platform-type">
        <div class="platform-type-left">主体类型</div>
        <div class="platform-type-right">
          <el-checkbox class="check-all" v-model="checkAllTwo" @change="subjectCheckAll">
            全选
          </el-checkbox>
          <el-checkbox-group
            class="checkbox-group"
            v-model="checkedSubject"
            @change="subjectChecked"
          >
            <el-checkbox
              class="check-more"
              v-for="item in subjectType"
              :label="item.value"
              :key="item.id"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="platform-type">
        <div class="platform-type-left">功能</div>
        <div class="platform-type-right">
          <el-checkbox-group class="checkbox-group" v-model="checkedAuthentication">
            <el-checkbox
              class="check-more"
              v-for="item in authenticationType"
              :label="item"
              :key="item"
            >
              {{ item }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="fans-box">
        <el-select class="select" v-model="fansSection" placeholder="请选择" @change="optionChange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          class="input"
          v-model="condition"
          clearable
          placeholder="请输入搜索内容"
        ></el-input>
        <div class="fans-num">时间</div>
        <el-date-picker
          v-model="releaseTime"
          class="lelect-time"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        <div class="fans-num">错误类型</div>
        <el-select class="select-two" v-model="infoType" clearable placeholder="请选择">
          <el-option
            v-for="item in infoTypeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <div class="fans-num">状态</div>
        <el-select class="select-two" v-model="readStateType" clearable placeholder="请选择">
          <el-option
            v-for="item in readState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="search" @click="searchList">查询</div>
        <div class="reset" style="position: absolute; right: 0.625rem" @click="resetList">
          重置
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 1rem; background: #f4f5fa"></div>
    <!-- 列表 -->
    <div class="data-list">
      <el-skeleton :rows="18" animated :loading="errorloading" />
      <div class="data-box" v-for="(item, index) in tableData" :key="index">
        <el-checkbox-group v-model="checkList" class="data-box">
          <div class="data-box-left">
            <div class="platform-type" style="width: 8.125rem" v-if="item.platformTypeName">
              {{ item.platformTypeName }}
            </div>
            <div
              class="platform-type"
              style="background: #ff4a1d; width: 8.125rem"
              v-if="item.planName"
            >
              {{ item.planName }}
            </div>
          </div>
          <div class="data-box-right">
            <div class="name-box">
              <div class="title">
                <div class="status" v-if="item.isWarning == 1">已预警</div>
                <div class="name" @click="detail(item)" v-html="item.title"></div>
                <div class="bad-num" @click="viewOriginalText(item)">
                  <img src="@/assets/img/wang/article.png" />
                  查看原文
                </div>
              </div>
              <div class="read"></div>
            </div>
            <div class="content">
              <div class="content-text" v-html="item.content"></div>
            </div>
            <div class="data-info">
              <div class="data-info-left">
                <div class="region">平台类型：{{ item.platformTypeName || "无" }}</div>
                <div class="region" :title="item.author">账号名称：{{ item.author || "无" }}</div>
                <div class="region">主体类型：{{ item.bodyTypeName || "无" }}</div>
                <div class="region">主体名称：{{ item.source || "无" }}</div>
                <div class="region regionDQ">所在地区：{{ item.areaName || "无" }}</div>
                <div class="bad-formulation" :title="item.matchKeyword">
                  错误表述：{{ item.matchKeyword || "无" }}
                </div>
                <div class="bad-formulation" style="color: #02bc7c" :title="item.correctKeyword">
                  建议更正：{{ item.correctKeyword || "无" }}
                </div>
              </div>
              <div class="data-info-right">{{ item.publishTime }}</div>
            </div>
            <div class="operation">
              <div class="operation-right">
                <div class="programme" @click="dataCollection(item, index)">
                  <div class="collectionDiv" v-if="item.isCollect == 0">
                    <img src="@/assets/img/wang/collection.png" alt="" />
                    <div>收藏</div>
                  </div>
                  <div class="collectionDiv" v-else>
                    <img src="@/assets/img/zhang/yishoucang.png" alt="" />
                    <div style="color: #ff9000">已收藏</div>
                  </div>
                </div>
                <div class="detail" @click="$main.copyFun(item.content)">
                  <img src="@/assets/img/wang/content-info.png" alt="" />
                  <div>复制原文</div>
                </div>
                <div class="detail" @click="$main.copyFun(item.sourceUrl)">
                  <img src="@/assets/img/wang/copy-link.png" alt="" />
                  <div>复制链接</div>
                </div>
                <div class="detail" @click="$main.copyFun(item.content)">
                  <img src="@/assets/img/wang/copy-content.png" alt="" />
                  <div>复制内容</div>
                </div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
        <img
          class="disposed"
          src="@/assets/img/wang/disposed.png"
          alt=""
          v-if="item.isDisposal == 1"
        />
        <img class="disposed" src="@/assets/img/wang/undisposed.png" alt="" v-else />
      </div>
      <Bitmap v-if="!errorloading && tableData.length == 0" />
      <Pagination
        :pageNumber="page.pageNumber"
        :pageSize="page.pageSize"
        :total="total"
        @SizeChange="SizeChange"
        @CurrentChange="CurrentChange"
      ></Pagination>
    </div>
    <!-- 删除二次确认框 -->
    <deleteDialog ref="openDialog" @deleteFn="dataDelete"></deleteDialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import deleteDialog from "@/components/deleteDialog.vue";
import Error from "@/api/error/index";
import Bitmap from "@/components/bitmap.vue";

export default {
  components: {
    Pagination,
    deleteDialog,
    Bitmap,
  },
  data() {
    return {
      errorloading: false, //加载效果
      areaPathNameList: [], //所属地区选项
      checkAllOne: false, //平台类型全选
      checkedPlatform: [], //平台类型已选
      platformType: [], //平台类型
      isIndeterminateOne: false, //平台类型不确定状态
      checkAllTwo: false, //主体类型全选
      checkedSubject: [], //主体类型已选
      subjectType: [], //主体类型
      isIndeterminateTwo: false, //主体类型不确定状态
      checkedAuthentication: [], //已选状态
      authenticationType: ["已处置", "已预警"], //状态
      // 是否已读
      readStateType: "",
      // 是否已读选项
      readState: [
        { label: "已读", value: 1 },
        { label: "未读", value: 2 },
      ],
      condition: "", //搜索条件
      fansSection: 1, //粉丝数量区间
      options: [
        { label: "文章标题", value: 1 },
        { label: "文章内容", value: 2 },
        { label: "文章来源", value: 3 },
      ],
      infoType: "", //信息类型
      infoTypeOptions: [], //信息类型
      releaseTime: null, //发布时间
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      checkList: [],
    };
  },
  created() {
    this.getPlatformList();
    this.getSubjectList();
    this.getErrorTypeList();
    this.getErrorExpressionList();
  },
  methods: {
    // 一键已读
    async oneClickRead() {
      if (this.checkList.length == 0) {
        this.$message.warning("请选择需要已读的数据！");
        return false;
      }
      const res = await Error.oneClickRead(this.checkList.join(","));
      if (res.data.code == 200) {
        this.$message.success("操作成功！");
        this.checkList = [];
        this.getErrorExpressionList();
      }
    },
    // 数据删除
    async dataDelete(item) {
      const res = await Error.dataDelete(item.item.id);
      if (res.data.code == 200) {
        this.$message.success("删除成功！");
        this.getErrorExpressionList();
      }
    },
    // 打开删除弹窗
    openError(item) {
      item.message = "此条错误表述数据";
      this.$refs.openDialog.openDialog(item);
    },
    // 数据收藏或取消收藏
    async dataCollection(item, index) {
      if (item.isCollect == 0) {
        const res = await Error.dataCollection(item.id);
        if (res.data.code == 200) {
          this.tableData[index].isCollect = 1;
          this.$message.success("收藏成功！");
        }
      } else {
        const res = await Error.dataNoCollection(item.id);
        if (res.data.code == 200) {
          this.tableData[index].isCollect = 0;
          this.$message.success("取消收藏成功！");
        }
      }
    },
    // 查询列表
    searchList() {
      this.page = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.total = 10;
      this.getErrorExpressionList();
    },
    // 查询重置
    resetList() {
      this.checkAllOne = false;
      this.checkedPlatform = [];
      this.checkAllTwo = false;
      this.checkedSubject = [];
      this.checkedAuthentication = [];
      this.fansSection = 1;
      this.condition = "";
      this.releaseTime = null;
      this.readStateType = "";
      this.infoType = "";
      this.page = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.total = 10;
      this.getErrorExpressionList();
    },
    // 查看原文
    viewOriginalText(item) {
      window.open(item.sourceUrl);
    },
    // 选项清空输入框
    optionChange() {
      this.condition = "";
    },
    // 获取错误表述数据列表
    async getErrorExpressionList() {
      this.tableData = [];
      this.errorloading = true;
      let data = {
        page: this.page.pageNumber,
        limit: this.page.pageSize,
        isCollect: 1,
      };
      if (this.checkedPlatform.length > 0) {
        data.platformTypes = this.checkedPlatform.join(",");
      }
      if (this.checkedSubject.length > 0) {
        data.bodyTypes = this.checkedSubject.join(",");
      }
      if (this.checkedAuthentication.length > 0) {
        if (this.checkedAuthentication.indexOf("已处置") != -1) {
          data.isDisposal = 1;
        }
        if (this.checkedAuthentication.indexOf("已预警") != -1) {
          data.isWarning = 1;
        }
        if (this.checkedAuthentication.indexOf("已收藏") != -1) {
          data.isCollect = 1;
        }
      }
      if (this.releaseTime) {
        data.publishTimeStart = this.releaseTime[0];
        data.publishTimeEnd = this.releaseTime[1];
      }
      if (this.readStateType != "") {
        if (this.readStateType == 1) {
          data.isRead = 1;
        } else if (this.readStateType == 2) {
          data.isRead = 0;
        } else {
          data.isReadConfine = 1;
        }
      }
      if (this.condition != "") {
        if (this.fansSection == 1) {
          data.title = this.condition;
        } else if (this.fansSection == 2) {
          data.content = this.condition;
        } else {
          data.source = this.condition;
        }
      }
      if (this.infoType != "") {
        data.planId = this.infoType;
      }
      const res = await Error.getErrorExpressionList(data);
      if (res && res.data && res.data.code == 200) {
        this.total = res.data.data.totalCount / 1;
        res.data.data.list.forEach((x) => {
          x.publishTime = this.formatDate(x.publishTime);
          x.matchKeyword?.split(" ").forEach((item) => {
            let regExp = new RegExp(item, "g");
            x.title = x.title.replace(regExp, "<font color='red'>" + item + "</font>");
            x.content = x.content.replace(regExp, "<font color='red'>" + item + "</font>");
          });
        });
        this.tableData = res.data.data.list;
        this.errorloading = false;
      } else {
        this.tableData = [];
        this.errorloading = false;
      }
    },
    // 获取所属地区
    async getAreaPathName(areaId) {
      const res = await Error.getAreaPathName(areaId);
      return res.data.data;
    },
    // 获取错误类型
    async getErrorTypeList() {
      const res = await Error.getMisrepresentationSelect();
      if (res && res.data && res.data.code == 200) {
        this.infoTypeOptions = res.data.data;
      }
    },
    // 获取平台类型
    async getPlatformList() {
      const res = await Error.getPlatformList();
      if (res.data.code == 200) {
        this.platformType = res.data.data;
      }
    },
    // 获取主体类型
    async getSubjectList() {
      const res = await Error.getSubjectList();
      if (res.data.code == 200) {
        this.subjectType = res.data.data;
      }
    },
    //全选平台类型
    platformCheckAll(val) {
      let checked = this.platformType.map((item) => {
        return item.value;
      });
      this.checkedPlatform = val ? checked : [];
      this.isIndeterminateOne = false;
    },
    //多选平台类型
    platformChecked(value) {
      let checkedCount = value.length;
      this.checkAllOne = checkedCount === this.platformType.length;
      this.isIndeterminateOne = checkedCount > 0 && checkedCount < this.platformType.length;
    },
    //主体类型全选
    subjectCheckAll(val) {
      let checked = this.subjectType.map((item) => {
        return item.value;
      });
      this.checkedSubject = val ? checked : [];
      this.isIndeterminateTwo = false;
    },
    //主体类型多选
    subjectChecked(value) {
      let checkedCount = value.length;
      this.checkAllTwo = checkedCount === this.subjectType.length;
      this.isIndeterminateTwo = checkedCount > 0 && checkedCount < this.subjectType.length;
    },
    SizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNumber = 1;
      this.getErrorExpressionList();
    },
    CurrentChange(val) {
      this.page.pageNumber = val;
      this.getErrorExpressionList();
    },
    // 时间转换方法
    formatDate(d) {
      var date = new Date(d);
      var YY = date.getFullYear() + "-";
      var MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    //详情
    detail(row) {
      let routeData = this.$router.resolve({
        path: "/settings/errorDetail",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: row.id,
              platformType: row.platformTypeName,
              bodyType: row.bodyType,
              publishTime: row.publishTime,
              planName: row.planName,
            })
          ),
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.pageBox {
  display: flex;
  flex-direction: column;
  .account {
    width: 120px;
    height: 37px;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 37px;
    margin-bottom: 24px;
  }
  .platform-head {
    display: flex;
    flex-direction: column;
    padding: 0px 35px;
    height: auto;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    .platform-type {
      display: flex;
      min-height: 52px;
      width: 100%;
      height: auto;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      border-bottom: 1px solid #e4e6ec;
      .platform-type-left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 16px;
        width: 96px;
        height: 100%;
      }
      .platform-type-right {
        display: flex;
        width: calc(100% - 96px);
        height: 100%;
        .check-all {
          //全选
          margin: 16px 48px 0px 0px;
          ::v-deep .el-checkbox__label {
            color: #333333;
          }
          ::v-deep .el-checkbox__inner {
            border-color: #e4e6ec;
          }
          ::v-deep .el-checkbox__input.is-checked {
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
          ::v-deep .el-checkbox__input.is-indeterminate {
            //半选
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
        }
        .checkbox-group {
          width: calc(100% - 100px);
          height: 100%;
          padding-bottom: 16px;
          .check-more {
            //多选
            margin: 16px 48px 0px 0px;
            ::v-deep .el-checkbox__label {
              color: #333333;
            }
            ::v-deep .el-checkbox__inner {
              border-color: #e4e6ec;
            }
          }
          ::v-deep .el-checkbox.check-more.is-checked {
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
        }
        ::v-deep .el-radio {
          margin-top: 18px;
          margin-right: 34px;
          .el-radio__label {
            color: #333333;
          }
          .el-radio__inner {
            border-color: #e4e6ec;
          }
          .el-radio__input.is-checked {
            .el-radio__inner {
              background-color: #f58030;
            }
          }
        }
      }
    }
    .fans-box {
      display: flex;
      align-items: center;
      height: 68px;
      width: 100%;
      position: relative;
      .fans-num {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 12px;
        margin-left: 24px;
        white-space: nowrap;
      }
      .input {
        //输入框
        width: 240px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 240px;
          border-radius: 4px;
        }
        ::v-deep .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .select {
        //下拉选择
        width: 120px;
        height: 36px;
        margin: 0px 24px 0 0px;
        ::v-deep .el-input__inner {
          width: 120px;
          height: 36px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .select-two {
        width: 190px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 190px;
          height: 36px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      ::v-deep
        .el-date-editor.el-range-editor.el-input__inner.lelect-time.el-date-editor--daterange {
        height: 36px;
        width: 280px;
        border-radius: 4px;
        .el-range-input {
          font-size: 13px;
          width: 100px;
        }
        .el-range-input:nth-child(4) {
          margin-left: 10px;
        }
        .el-input__icon.el-range__icon.el-icon-date {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .el-input__icon.el-range__close-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .el-range-separator {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
        }
      }
      .search {
        display: flex;
        // align-items: center;
        // justify-content: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        width: auto;
        // height: 32px;
        background: #f58030;
        border-radius: 4px;
        padding: 6px 16px;
        cursor: pointer;
        position: absolute;
        right: 85px;
      }
      .reset {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        cursor: pointer;
      }
    }
    .screen-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;
      .select-type {
        width: 80px;
        height: 36px;
        margin-right: 24px;
        ::v-deep .el-input__inner {
          width: 80px;
          height: 36px;
          border-radius: 4px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .input-content {
        width: 240px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 240px;
          border-radius: 4px;
        }
        ::v-deep .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .address {
        font-size: 14px;
        white-space: nowrap;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 0 12px 0 24px;
      }
      .select-address {
        width: 190px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 190px;
          height: 36px;
          border-radius: 4px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .query {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        background: #f58030;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 70px;
        cursor: pointer;
      }
      .data-import {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 97px;
        height: 36px;
        background: #fff1e8;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ed731f;
        margin-left: 16px;
        cursor: pointer;
        img {
          height: 14px;
          width: 14px;
          margin-right: 2px;
        }
      }
      .reset {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
  .account-number {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    width: 100%;
    height: 68px;
    .add-account {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 128px;
      height: 36px;
      background: #f58030;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      img {
        height: 12px;
        width: 12px;
        margin-right: 2px;
      }
    }
  }
  .export {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 36px;
    margin-top: 16px;
    .data-export {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 98px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      background: #ed731f;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 16px;
      img {
        height: 14px;
        width: 14px;
        margin-right: 3px;
      }
    }
    .readed {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 98px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      background: #ffffff;
      border-radius: 4px;
      cursor: pointer;
      img {
        height: 12px;
        width: 14px;
        margin-right: 6px;
      }
    }
  }
  .data-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 4px;
    padding: 0px 35px;
    padding-bottom: 15px;
    margin-top: 16px;
    ::v-deep .el-loading-spinner .path {
      stroke: #ed731f;
    }
    .data-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      padding: 24px 0px;
      border-bottom: 1px solid #e4e6ec;
      .disposed {
        height: 114px;
        width: 129px;
        position: absolute;
        right: 138px;
      }
      .data-box-left {
        width: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .check {
          position: absolute;
          margin-left: -126px;
        }
        .type {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          height: 28px;
          background: #ff4b1f;
          border-radius: 1px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .platform-type {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          width: auto;
          background: #f58030;
          max-width: 104px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 24px;
        }
      }
      .data-box-right {
        display: flex;
        flex-direction: column;
        width: calc(100% - 160px);
        height: 100%;
        .name-box {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          width: 100%;
          height: 25px;
          margin-bottom: 16px;

          .title {
            display: flex;
            width: 80%;
            height: 25px;
            .status {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              background: #ffedef;
              padding: 0 8px;
              margin-right: 16px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ea3342;
            }
            .name {
              height: 25px;
              max-width: calc(100% - 160px);
              width: auto;
              line-height: 25px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
              color: #333333;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .bad-num {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2e59ec;
              margin-left: 18px;
              cursor: pointer;
              img {
                height: 16px;
                width: 18px;
                margin-right: 8px;
              }
            }
          }
          .read {
            display: flex;
            justify-content: flex-end;
            width: 20%;
            .similar {
              display: flex;
              align-items: center;
              width: auto;
              height: 25px;
              margin-right: 8px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
            .unread {
              display: flex;
              align-items: center;
              width: auto;
              height: 25px;
              img {
                width: 17px;
                height: 12px;
                margin-right: 8px;
              }
              span {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ea3342;
              }
            }
          }
        }
        .content {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 16px;
          img {
            height: 52px;
            width: 52px;
            margin-right: 13px;
            border-radius: 50%;
          }
          .content-text {
            width: 100%;
            max-height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .data-info {
          display: flex;
          height: 20px;
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 16px;
          .data-info-left {
            display: flex;
            align-items: center;
            width: 85%;
            height: 20px;
            .subject-type {
              margin-right: 46px;
            }
            .bad-formulation {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: red;
              max-width: 150px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 30px;
            }
          }
          .data-info-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 20px;
            width: 15%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          .status {
            color: #02bc7c;
            margin-right: 46px;
          }
          .region {
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 30px;
          }
          .regionDQ {
            margin-right: 70px;
          }

          .company {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 46px;
          }
          .fnas {
            margin-right: 46px;
          }
        }
        .operation {
          display: flex;
          height: 20px;
          width: 100%;
          .operation-left {
            display: flex;
            align-items: center;
            width: 20%;
            height: 100%;
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-right: 8px;
            }
          }
          .operation-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            .detail {
              display: flex;
              height: 100%;
              width: auto;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              cursor: pointer;
              margin-right: 40px;
              img {
                height: 14px;
                width: 13px;
                margin-right: 7px;
              }
            }
          }
          .programme {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            margin-right: 40px;
            img {
              height: 14px;
              width: 15px;
              margin-right: 7px;
            }
            .collectionDiv {
              display: flex;
              align-items: center;
            }
          }
          .remove {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 15px;
              width: 15px;
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}
</style>
