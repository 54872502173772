// 我的词库
import request from "../request.js";

// 创建机构错误词
export function createOrgMisreWords(data) {
  return request({
    url: '/module-system/orgMisre/createOrgMisreWords',
    method: 'post',
    data
  })
}
// 删除机构正确词
export function delOrgCorrectWord(wordId) {
  return request({
    url: `/module-system/orgMisre/delOrgCorrectWord/${wordId}`,
    method: 'get',
  })
}
// 删除机构错误词
export function delOrgWrongWord(wordId) {
  return request({
    url: `/module-system/orgMisre/delOrgWrongWord/${wordId}`,
    method: 'get',
  })
}


// 分页查询机构错误词列表
export function queryOrgMisreWords(data) {
  return request({
    url: `/module-system/orgMisre/queryOrgMisreWords`,
    method: 'post',
    data
  })
}

// 分页查询机构错误词操作日志
export function orgWordLogList(data) {
  return request({
    url: `/module-system/orgMisre/orgWordLogList`,
    method: 'post',
    data
  })
}
// 查询机构操作日志详情
export function orgWordLogDetail(params) {
  return request({
    url: `/module-system/orgMisre/orgWordLogDetail`,
    method: 'get',
    params
  })
}
